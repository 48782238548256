<style scoped lang="less">
.Cloud_ListModel {
  .folder {
    width: 100%;
    height: 90px;
  }
  .coverUrl {
    width: 110px;
    height: 80px;
    background: #f5f7fa;
    border-radius: 4px;
  }

  .image-none {
    border-radius: 4px;
    width: 100px;
    height: 70px;
    margin: 6px auto;
    display: block;
  }
}
</style>
<template>
  <div class="Cloud_ListModel">
    <div>
      <div class="title">我的网盘：</div>
      <el-button class="rootBtn" type="text" @click="handleListRootNav">
        根目录
      </el-button>
    </div>
    <el-table
      :data="data"
      :height="450"
      @selection-change="selectionChange"
      @row-click="rowClick"
    >
      <el-table-column
        type="selection"
        align="center"
        width="60"
      ></el-table-column>
      <el-table-column label="序号" align="center" width="70">
        <template slot-scope="scope">
          <span>{{ scope.$index + (page - 1) * pageSize + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="160">
        <template slot-scope="scope">
          <div v-if="scope.row.fileType === 0" class="folder">
            <svg-icon name="folder"></svg-icon>
          </div>
          <el-image
            v-else-if="scope.row.fileUrl || scope.row.fileUrl === null"
            :src="scope.row.fileUrl"
            class="coverUrl"
          >
            <div slot="error" class="image-none">
              <img
                class="image-none"
                src="../../../../../assets/logo_top.png"
              />
            </div>
          </el-image>
        </template>
      </el-table-column>

      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        :min-width="item.width"
        :show-overflow-tooltip="item.tooltip"
        align="center"
      ></el-table-column>
      <el-table-column align="center" width="160" prop="fileType" label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.fileType == 0">文件夹</span>
          <span v-else-if="scope.row.fileType == 1">文件</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        prop="fileSize"
        label="文件大小"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.fileSize | numFilter }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { EduNetdiskDownloadById } from "@/libs/api/teacher/cloud";
export default {
  name: "cloud_listModel",
  props: {
    data: Array,
    columns: Array,
    total: Number,
    page: Number,
    pageSize: Number,
  },
  data() {
    return {
      selectionData: [],
    };
  },
  filters: {
    numFilter(value) {
      let realVal = (value / 1024).toFixed(2);
      if (realVal > 1024) {
        return (realVal / 1024).toFixed(2) + "M";
      }
      return realVal + "KB";
    },
  },
  methods: {
    // list根目录
    handleListRootNav() {},
    selectionChange(val) {
      this.selectionData = val;
      this.$emit("handleSelection", val);
    },
    handleCurrentChange(page) {
      if (page) {
        this.$emit("pageInit", page);
      }
    },
    // 点击行
    rowClick(row) {
      if (row.type === 0) {
      }
    },
  },
};
</script>
