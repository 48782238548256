import { render, staticRenderFns } from "./ListModel.vue?vue&type=template&id=f5257f3e&scoped=true"
import script from "./ListModel.vue?vue&type=script&lang=js"
export * from "./ListModel.vue?vue&type=script&lang=js"
import style0 from "./ListModel.vue?vue&type=style&index=0&id=f5257f3e&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5257f3e",
  null
  
)

export default component.exports