
<style scoped lang="less">
.Cloud_ViewModel {
  .items {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border: 2px solid #ececec;
    border-radius: 8px;
    width: 160px;
    height: 170px;
    padding: 10px 0 0px;
    .el-checkbox {
      margin-left: 10px;
    }
    .folder {
      margin-top: 10px;
      width: 100%;
      height: 120px;
    }
    .fileUrl {
      cursor: pointer;
      margin: 10px 0;
      width: 180px;
      height: 110px;
      background: #f5f7fa;
      border-radius: 4px;
    }
    .image-none {
      border-radius: 4px;
      width: 110px;
      height: 80px;
      margin: 15px auto;
      display: block;
    }
  }
  .fileName {
    text-align: center;
    width: 160px;
    margin-top: 10px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
<template>
  <div class="Cloud_ViewModel">
    <div>
      <div class="title">
        我的网盘：<el-button type="primary">全选</el-button>
      </div>
      <el-button class="rootBtn" type="text" @click="handleViewRootNav">
        根目录
      </el-button>
    </div>

    <el-row :gutter="30">
      <el-col
        v-for="(item, index) in data"
        :key="index"
        :xs="24"
        :sm="12"
        :md="8"
        :lg="4"
      >
        <div class="items">
          <el-checkbox v-model="item.check"></el-checkbox>
          <div v-if="item.fileType === 0" class="folder">
            <svg-icon name="folder"></svg-icon>
          </div>
          <el-image v-else :src="item.fileUrl" class="fileUrl">
            <div slot="error" class="image-none">
              <img
                class="image-none"
                src="../../../../../assets/logo_top.png"
              />
            </div>
          </el-image>
        </div>
        <el-tooltip
          class="fileName"
          effect="dark"
          :content="item.fileName"
          placement="top"
        >
          <span>{{ item.fileName }}</span>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "cloud_viewModel",
  props: {
    data: Array,
    total: Number,
    page: Number,
    pageSize: Number,
  },
  data() {
    return {
      selectionSelect: [],
    };
  },
  methods: {
    // view根目录
    handleViewRootNav() {},
    handleCurrentChange(page) {
      if (page) {
        this.$emit("pageInit", page);
      }
    },
    checkedWith(bool) {
      let arr = [];
      let data = this.data;
      data.forEach((item) => {
        this.$set(item, "check", bool);
        if (item.check) arr.push(item);
      });
      this.selectionSelect = arr;
      this.$emit("handleSelection", arr);
    },
    // 全选
    selectAll() {
      this.checkedWith(true);
    },
    // 选中
    handleChange(row) {
      if (row) {
        const list = this.selectionSelect.map((m) => m.id);
        if (!list.includes(row.id)) {
          this.selectionSelect.push(row);
        } else {
          const index = list.findIndex((f) => f === row.id);
          this.selectionSelect.splice(index, 1);
        }
        this.$emit("handleSelection", this.selectionSelect);
      }
    },
  },
};
</script>
