<style scope lang="less">
.t_module_recycle {
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .dropdown_btn {
    margin-right: 10px;
  }
  .content {
    padding: 20px;
    .form_div {
      display: flex;
      align-items: center;
      .el-input {
        width: 230px;
      }
      .view_type {
        margin-left: 20px;
        i {
          margin-right: 10px;
          cursor: pointer;
          font-size: 20px;
        }
        .active {
          color: @mainColor;
        }
      }
    }
    .title {
      margin-top: 20px;
      height: 32px;
      line-height: 32px;
    }
    .rootBtn {
      margin: 5px 0;
    }
  }
}
</style>
<template>
  <div class="t_module_recycle">
    <div class="stu-module-header">
      <div class="stu-module-title">我的回收站</div>
      <div>
        <el-button type="primary" @click="handleReduction">还原文件</el-button>
        <el-button type="primary" @click="handleDelete">彻底删除</el-button>
      </div>
    </div>
    <div class="content">
      <div class="form_div">
        <el-input v-model="info" size="medium" placeholder="搜索文件">
          <el-button
            slot="append"
            @click="query"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <div class="view_type">
          <i
            @click="active = true"
            class="el-icon-s-operation"
            :class="active ? 'active' : ''"
          ></i>
          <i
            @click="active = false"
            class="el-icon-s-grid"
            :class="!active ? 'active' : ''"
          ></i>
        </div>
      </div>
      <div v-loading="loading">
        <ListModel
          v-if="active"
          :data="modelData"
          :columns="columns"
          :total="total"
          :page="page"
          :pageSize="pageSize"
          @pageInit="pageInit"
          @handleSelection="handleSelection"
        >
        </ListModel>
        <view-model
          v-else
          :data="modelData"
          :total="total"
          :page="page"
          :pageSize="pageSize"
        ></view-model>
      </div>
    </div>
  </div>
</template>

<script>
import ListModel from "./model/ListModel";
import ViewModel from "./model/ViewModel";
import {
  EduNetdiskDelList, // 查询
  EduNetdiskDeleteCompletely, // 彻底删除
  EduNetdiskRecovery, // 还原
} from "@/libs/api/teacher/cloud";
export default {
  name: "t_module_recycle",
  components: { ListModel, ViewModel },
  data() {
    return {
      info: "",
      active: true,
      loading: false,
      selectionData: [],
      modelData: [],
      columns: [
        { label: "文件名称", prop: "fileName", tooltip: true },
        { label: "创建时间", prop: "createTime", width: 140, tooltip: true },
      ],
      total: 0,
      page: 1,
      pageSize: 12,
    };
  },
  watch: {
    active() {
      this.selectionData = [];
    },
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      let data = new FormData();
      data.append("page", this.page);
      data.append("pageSize", this.pageSize);
      data.append("info", this.info);
      this.loading = true;
      EduNetdiskDelList(data)
        .then((res) => {
          this.loading = false;
          this.modelData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSelection(val) {
      this.selectionData = val;
    },
    // 还原
    handleReduction() {
      if (!this.selectionData.length) {
        return this.$message.error("请选择数据");
      }
      let data = new FormData();
      data.append("ids", this.selectionData.map((m) => m.id).join());
      EduNetdiskRecovery(data).then((res) => {
        this.query();
        this.$message.success("还原成功");
      });
    },
    // 彻底删除
    handleDelete() {
      if (!this.selectionData.length) {
        return this.$message.error("请选择数据");
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = new FormData();
          data.append("ids", this.selectionData.map((m) => m.id).join());
          EduNetdiskDeleteCompletely(data).then((res) => {
            this.query();
            this.$message.success("删除成功");
          });
        })
        .catch(() => {});
    },
    pageInit(page) {
      this.page = page;
      this.query();
    },
  },
};
</script>
